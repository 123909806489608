<template>
  <section class="v-select">
    <section>
      <el-autocomplete
        v-model="selectValue"
        class="inline-input"
        :fetch-suggestions="querySearch"
        placeholder="Please enter"
        :value-key="valueKey"
        :disabled="disabled"
        @select="handleSelect"
        @change="handlerChange"
      ></el-autocomplete>
    </section>

    <!-- vant -->
    <!--    <section class="show-vant">-->
    <!--      <van-field-->
    <!--        readonly-->
    <!--        clickable-->
    <!--        :rules="rules"-->
    <!--        :value="selectLabel"-->
    <!--        class="v-input"-->
    <!--        :label="label"-->
    <!--        :disabled="disabled"-->
    <!--        :name="name"-->
    <!--        right-icon="arrow-down"-->
    <!--        @click="onClick">-->
    <!--      </van-field>-->

    <!--      <van-popup-->
    <!--        v-model="show"-->
    <!--        position="bottom"-->
    <!--      >-->
    <!--        <van-picker-->
    <!--          v-show="show"-->
    <!--          show-toolbar-->
    <!--          :columns="columns"-->
    <!--          :readonly="disabled"-->
    <!--          @cancel="onCancel"-->
    <!--          @confirm="onConfirm"-->
    <!--        />-->
    <!--      </van-popup>-->
    <!--    </section>-->
  </section>

</template>

<script>
export default {
  name: 'VInputSelect',
  props: {
    value: {
      type: [String, Number],
      default: ''
    },
    remoteMethod:{
      type:Function
    },
    list: {
      type: Array,
      default: () => ([])
    },
    labelKey: {
      type: String,
      default: 'name'
    },
    valueKey: {
      type: String,
      default: 'id'
    },
    placeholder: {
      type: String,
      default: ''
    },
    filterable: {
      type: Boolean,
      default: true
    },
    remote: {
      type: Boolean,
      default: false
    },
    clearable: {
      type: Boolean,
      default: false
    },
    disabled: {
      type: Boolean,
      default: false
    },
    icon: {
      type: String,
      default: ''
    },
    iconType: {
      type: String,
      default: 'fas'
    },
    // vant
    name: {
      type: String,
      default: ''
    },
    label: {
      type: String,
      default: ''
    },
    rules: {
      type: Array,
      default: () => ([])
    },
    blue: {
      type: Boolean,
      default: false
    },
    border: {
      type: Boolean,
      default: false
    },
    borderBottom: {
      type: Boolean,
      default: false
    },
    borderBottomBlue: {
      type: Boolean,
      default: false
    },
    defaultOption:{
      type: Boolean,
      default: true
    },
    // 是否显示第一个默认的值
    isShowFirstValue: {
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
      selectValue: '',
      // vant
      text: '',
      //   selectValue: '',
      selectLabel: '',
      show: false,
      options:this.list
    }
  },
  computed: {
    columns() {
      if(this.list && this.list.length > 0) {
        return this.list.reduce((result, item) => {
          result.push(item[this.labelKey])
          return result
        }, [])
      }
      return []
    },
    isNumber() {
      if(!(this.list instanceof Array) || this.list.length == 0 ) {
        return false
      }
      let id = this.list[0][this.valueKey]
      return typeof id === 'number'? true: false
    }
  },
  watch: {
    value: {
      immediate: true,
      handler(val, old) {
        if(val === '') {
          this.selectValue = ''
          this.selectLabel = ''
        }
        this.selectValue = val
        // if (val !== this.selectValue ) {
        //   this.handleValue(val)
        // }
      }
    },
    list: {
      deep: true,
      immediate:true,
      handler(val) {
        this.options= val
        if(val && val.length > 0) {
          // this.handleValue(this.selectValue)
        }
      }
    },
    selectValue(val) {
      this.$emit('input', val)
      const result = this.getOptionData(val)
      if(result && this.selectLabel !== result[this.labelKey]) {
        this.selectLabel = result[this.labelKey]
      }
    }
  },
  created() {
    // // 设置默认选中第一个值
    // if (this.isShowFirstValue && this.list.length>0) {
    //   this.currentValue = this.list[0][this.valueKey]
    //   this.$emit('input', this.list[0][this.valueKey])
    // }
  },
  methods: {
    querySearch(queryString, cb) {
      var restaurants = this.list
      var results = queryString ? restaurants.filter(this.createFilter(queryString)) : restaurants
      // 调用 callback 返回建议列表的数据
      cb(results)
    },
    createFilter(queryString) {
      return (list) => {
        return (list[this.valueKey].toLowerCase().indexOf(queryString.toLowerCase()) === 0)
      }
    },
    dataFilter(val) {
      this.selectValue  =val
      if (val) { //val存在
        this.options = this.list.filter((item) => {
          if (item[this.labelKey] .indexOf(val)===0 || item[this.labelKey].toUpperCase().indexOf(val.toUpperCase())===0) {
            return true
          }
        })
      } else { //val为空时，还原数组
        this.options = this.list
      }
    },
    handleSelect(item) {
      this.selectValue = item[this.valueKey]
      // this.$emit('input', item[this.valueKey])
      // this.$emit('change', item[this.valueKey])
      // this.$emit('changeData', item[this.valueKey])
      // console.log('data===')
    },
    handlerChange(){
      this.$emit('input', this.selectValue)
      this.$emit('change', this.selectValue)
    },
    handleValue(value) {
      //   let flag = this.selectValue === ''
      //   // 排除外部干擾
      //   let list = this.list? this.list: []

      //   const val = this.isNumber? Number(value): String(value)

      //   let result = list.filter(item => item[this.valueKey] === val)

      //   this.selectValue = result.length > 0 ? val : ''
      //   if(flag && this.selectValue === '') {
      //     this.$emit('input', this.selectValue)
      //   }
      let flag = false
      if(this.list && this.list.length) {
        this.list.some(item => {
          if(item[this.valueKey] == value && value !== '') {
            this.selectLabel = item[this.labelKey]
            if(this.selectValue !== value) {
              this.selectValue = this.isNumber? Number(value): value
            }
            flag = true
            return true
          }
        })
      }
      // if(!flag) {
      //   this.selectValue = ''
      //   this.selectLabel = ''
      // }
    },
    getOptionData(val) {
      let result = {}
      this.list.some(item => {
        if (item[this.valueKey] == val) {
          result = item
          return true
        }
        return false
      })
      return result
    },
    // vant
    onClick() {
      if(this.disabled) return
      this.show = true
    },
    onCancel() {
      this.show = false
    },
    onConfirm(item, index) {
      this.selectValue = this.list[index][this.valueKey]
      this.selectLabel = item
      this.$emit('input', this.selectValue)
      this.$emit('change', this.selectValue)
      this.show = false
    },
  }
}
</script>

<style lang="scss" scoped>
.el-select {
  width: 100%;
  /deep/ .el-input__prefix {
    display: flex;
    align-items: center;
    padding-left: 3px;
    height: 100%;
  }
  .svg-inline--fa {
    font-size: 16px;
  }
}
.v-select {
  .v-input {
    margin-top: 20px;
  }
}
</style>

<style lang="scss">
.el-select-dropdown__item {
  height: auto;
  line-height: auto;
  .send-info {
    padding-bottom: 5px;
    span {
      display: block;
      line-height: 15px;
      font-size: 12px;
    }
  }
}
::v-deep .show-element{
  ::v-deep .el-autocomplete{
    width: 100%;
    height: 32px;
    line-height: 32px;
  }
}
</style>
