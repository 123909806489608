
import { getGlobalOptions } from '@/api/common'

const SET_GLOBAL_OPTIONS = 'SET_GLOBAL_OPTIONS'

const state = {
  globalOptions: null,
}

const mutations = {
  [SET_GLOBAL_OPTIONS]: (state, value) => {
    state.globalOptions = value
  },
}

const actions = {
  setGlobalOptions({ commit }, val) {
    commit(SET_GLOBAL_OPTIONS, val)
  },
  // 檢查是否有遺漏的 options 數據未獲取成功，重新請求一次
  checkOptionsData({ state, dispatch  }) {
    // 全局數據
    if(!state.globalOptions) {
      dispatch('updateGlobalOptions')
    }
  },
  updateGlobalOptions({ commit },params) {
    return new Promise((resolve, reject) => {
      getGlobalOptions({
        hideLoading: true,
        ...params
      }).then(res => {
        let { data } = res
        // 處理數據
        // const resultData = handlerOptionsData(data)
        commit(SET_GLOBAL_OPTIONS, data)
        sessionStorage.setItem('GLOBAL_OPTIONS', JSON.stringify(data))
        resolve(res)
      }).catch(error => {
        console.log(error)
        reject(error)
      })
    })
  },
}

// function handlerOptionsData(data) {
//   let result = {}
//   Object.keys(data).some(key => {
//     if(key == 'currency') {
//       let tempArr = []
//       for(let i = 0; i < data[key].length; i++) {
//         tempArr.push({
//           id: data[key][i],
//           name: data[key][i]
//         })
//       }
//       result[key] = tempArr
//     }
//     else {
//       result[key] = data[key]
//     }
//   })
//   return result
// }

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
